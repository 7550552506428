import React, { RefObject, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import calendarIcon from "~assets/calendar.svg";
import errorIcon from "~assets/error.svg";
import styles from "./Forms.scss";
import rootStyles from "~views/pages/Root.scss";
import { IInfoPopoverProps } from "../InfoPopover/InfoPopover";
import { DayPickerComponent } from "../DayPicker/DayPicker";
import { Matcher } from "react-day-picker";
import { LabelRow } from "../LabelRow/LabelRow";
import { Button } from "../Button/Button";
import { formatDate } from "netbank-shared/src/libs/utils";
import { tx } from "netbank-shared/src/libs/i18n";

interface IDatePickerFormFieldProps {
  name: string;
  label: string;
  disabledDays?: Matcher | Matcher[] | undefined;
  infoPopover?: IInfoPopoverProps;
  triggerValidation?: boolean;
  disabled?: boolean;
  initialMonth?: Date;
}

export const DatePickerFormField = ({
  label,
  name,
  disabledDays,
  infoPopover,
  triggerValidation,
  disabled,
  initialMonth,
}: IDatePickerFormFieldProps) => {
  const [open, setOpen] = useState(false);
  const ref: RefObject<HTMLDivElement> = React.useRef(null);
  const {
    control,
    formState: { errors, isSubmitting },
    trigger,
  } = useFormContext();
  const error = errors[name]?.message?.toString();

  const dayPickerClasses = [styles.dayPicker];

  if (open) {
    dayPickerClasses.push(styles.open);
  }

  const handleClickOutside = (e: MouseEvent) => {
    if (open && ref && e.target instanceof Node && !ref.current?.contains(e.target)) {
      setOpen(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div ref={ref} className={styles.dayPickerWrapper}>
          <LabelRow label={label} infoPopover={infoPopover} />
          <Button
            title={(field.value && formatDate(field.value)) || tx("misc.selectDate")}
            onClick={() => setOpen(!open)}
            borderColor="blue"
            active={open}
            iconSuffix={calendarIcon}
            bordered
            fullWidth
            disabled={disabled || isSubmitting}
          />
          {error && (
            <div className={rootStyles.error}>
              <img src={errorIcon} alt="error-icon" />
              <span>{error}</span>
            </div>
          )}
          <div className={dayPickerClasses.join(" ")}>
            <DayPickerComponent
              onDayClick={(date: Date) => {
                if (isSubmitting) return;
                field.onChange(date);
                if (triggerValidation) trigger();
                setOpen(false);
              }}
              initialMonth={initialMonth || field.value}
              selectedDays={field.value}
              disabledDays={disabledDays}
            />
          </div>
        </div>
      )}
    />
  );
};
