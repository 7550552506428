/* eslint-disable no-underscore-dangle */
import React, { RefObject, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { observer } from "mobx-react";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import { useFetchData, useStores, useTracking } from "netbank-shared/src/hooks";
import { authorize } from "netbank-shared/src/hoc/authorize";
import { Store } from "netbank-shared/src/stores";
import { Lang, PageType } from "netbank-shared/src/libs/models/Content/Enums";
import { isUrl } from "netbank-shared/src/libs/utils";
import { withStore } from "../../mobx-web";
import styles from "./Root.scss";
import { Header, Popup, Modal, SideNav, Footer, Notifications, Breadcrumbs } from "~views/shared";
import "~globalstyles/styles.scss";
import { Popover } from "~views/shared/Popover/Popover";
import { RootSkeleton } from "./Skeletons/RootSkeleton";
import { CookieConsent } from "~views/shared/CookieConsent/CookieConsent";
import { HomeSkeleton } from "./Skeletons/HomeSkeleton";
import { tx } from "netbank-shared/src/libs/i18n";
import { RetryPage } from "./RetryPage/RetryPage";
import { getLangInUrl } from "netbank-shared/src/libs/utils/url";
import { useLocation } from "react-router-dom";
import { CorporateHeader } from "~views/shared/CorporateHeader/CorporateHeader";

export interface RootProps {
  store: Store;
}

export const withRoot = (Comp: any) => {
  const Root = observer(({ store }: RootProps) => {
    const location = useLocation();
    const { uiStore, customerStore, contentStore, rootStore, messageStore } = useStores();
    const { loadingGiosg, isGiosgInitialized, initializeGiosg } = customerStore;
    const { currentCustomer } = customerStore;
    const { showMessageViews } = uiStore;
    const dataLayer = useTracking((window as any).dataLayer);
    const modalRef: RefObject<HTMLDivElement> = useRef(null);
    const confirmationPopupRef: RefObject<HTMLDivElement> = useRef(null);

    const modalActive = uiStore.modal?.content !== undefined && uiStore.modal?.content?.length > 0;

    const confirmationPopupActive = uiStore.confirmationPopup !== undefined;

    const popoverActive = uiStore.popover !== undefined;

    const pageTitle = contentStore.currentPage?.pageTitle
      ? `${contentStore.currentPage?.pageTitle} - Santander`
      : "Netbank - Santander";

    useEffect(() => {
      const loadHomepage = async () => {
        // Homepage should always be available
        let { homePage } = contentStore;
        if (!homePage) {
          homePage = await contentStore.getPage(`/${tx("routing.lang")}`, true, true);
        }
        const availableLanguages =
          (homePage?.languagePicker?.supportedLanguages?.split(",").filter((s) => s !== "") as Lang[]) || [];

        // Main lang should always be available
        if (uiStore.lang) {
          if (!availableLanguages.includes(uiStore.lang)) {
            availableLanguages.push(uiStore.lang);
          }

          if (!availableLanguages.includes(tx("routing.lang") as Lang)) {
            await contentStore.changeLanguage(uiStore.lang);
            rootStore.commonService.redirect(`/${uiStore.lang}`);
            return;
          }
        }

        const path = location.pathname;
        const isHomePage =
          path === "" || path === "/" || path === `/${tx("routing.lang")}` || path === `/${tx("routing.lang")}/`;
        if (!homePage || getLangInUrl(homePage.url) !== tx("routing.lang")) {
          await contentStore.getPage(`/${tx("routing.lang")}`, true, false, isHomePage);
        }
      };
      loadHomepage();
    }, []);

    const startPollingConversations = async () => {
      if (!messageStore.unreadAmount) {
        await messageStore.getConversations(false);
      }
      const conversationFetcher = setInterval(() => {
        if (messageStore.conversations && !messageStore.fetchingConversations && !messageStore.pollingConversations)
          messageStore.getConversations(false);
      }, 30000);
      return conversationFetcher;
    };

    useFetchData({
      condition: showMessageViews,
      callback: startPollingConversations,
      cleanup: (interval: NodeJS.Timeout) => clearInterval(interval),
      deps: [],
    });

    useEffect(() => {
      uiStore.popups = [];
      window.scrollTo(0, 0);

      if (uiStore.modal) uiStore.removeModal();
      if (uiStore.popover) uiStore.removePopover();

      // Skip first manual page view datalayer event due to existing automatic page load event
      if (location && dataLayer && !uiStore.isFirstPageview) {
        if (dataLayer) {
          dataLayer.pushPageView(location.pathname, location.search);
        }
      }
      if (uiStore.isFirstPageview) {
        uiStore.setIsFirstPageview(false);
      }
    }, [location]);

    useEffect(() => {
      if (modalActive) {
        if (modalRef.current) disableBodyScroll(modalRef.current);
      } else {
        clearAllBodyScrollLocks();
      }
    }, [modalActive]);

    useEffect(() => {
      if (confirmationPopupActive) {
        if (confirmationPopupRef.current) disableBodyScroll(confirmationPopupRef.current);
      } else {
        clearAllBodyScrollLocks();
      }
    }, [confirmationPopupActive]);

    useEffect(() => {
      if (window._giosg && !isGiosgInitialized && !loadingGiosg) {
        window._giosg(() => {
          const id = window.giosg.on("visitor:active", async () => {
            const { unpackedVisitorCid, allowedRooms } = window.giosg;
            initializeGiosg(
              unpackedVisitorCid,
              allowedRooms.map((room) => room.id),
            );
            window.giosg.off(id);
          });
        });
      }
    }, [window._giosg]);

    const isTermsPage = contentStore.currentPage?.contentType?.[1] === PageType.TermsPage;

    const contentWrapperClasses = [styles.contentWrapper];
    const contentClasses = [styles.content];

    if (uiStore.popups?.length > 0 || modalActive || popoverActive) {
      contentWrapperClasses.push(styles.fade);
    }

    if (contentStore.currentPage?.backgroundColor) {
      contentClasses.push(styles[contentStore.currentPage.backgroundColor]);
    }

    if (rootStore.loggingOut) {
      return (
        <RootSkeleton>
          <HomeSkeleton />
        </RootSkeleton>
      );
    }

    const pageHelmet = () => (
      <Helmet htmlAttributes={{ lang: tx("routing.locale") }}>
        <title>{pageTitle}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
    );

    const sandrineHelmet = (sandrineId?: string) => {
      if (!sandrineId || isGiosgInitialized) return null;

      return (
        <Helmet>
          <script>
            {
              // eslint-disable-next-line max-len
              `(function(w, t, f) {var s='script',o='_giosg',h='https://service.giosg.com',e,n;e=t.createElement(s);e.async=1;e.src=h+'/live2/'+f;w[o]=w[o]||function(){(w[o]._e=w[o]._e||[]).push(arguments)};w[o]._c=f;w[o]._h=h;n=t.getElementsByTagName(s)[0];n.parentNode.insertBefore(e,n);})(window,document,"${sandrineId}");`
            }
          </script>
        </Helmet>
      );
    };

    if (isTermsPage) {
      contentClasses.push(styles.termsPage);
      return (
        <>
          {pageHelmet()}
          <Header logoOnly />
          <div className={styles.main}>
            <div className="mainWrapper">
              <div className={contentClasses.join(" ")}>
                <Comp location={location} store={store} />
              </div>
            </div>
          </div>
        </>
      );
    }

    if (contentStore.isRetryPage) {
      return <RetryPage />;
    }

    return (
      <>
        {pageHelmet()}
        {sandrineHelmet(rootStore.config?.SANDRINE_ID)}
        {rootStore.config?.COOKIE_CONSENT_SCRIPT && isUrl(rootStore.config.COOKIE_CONSENT_SCRIPT) && (
          <CookieConsent cookieScript={rootStore.config.COOKIE_CONSENT_SCRIPT} />
        )}
        {uiStore.popups?.map((p, i) => (
          <div key={`popup-${i}`}>
            <Popup open={p.open} cancelAction={p.cancelAction} applyAction={p.applyAction} element={p.element}>
              {p.children}
            </Popup>
          </div>
        ))}
        {modalActive && <Modal modalScrollRef={modalRef} />}
        {popoverActive && <Popover />}
        <div className={contentWrapperClasses.join(" ")}>
          <Header />
          <div className={styles.main}>
            <SideNav />
            <div className="mainWrapper">
              <div className={contentClasses.join(" ")}>
                {currentCustomer && <Notifications />}
                <Breadcrumbs />
                {contentStore.getIsCorporateCustomerContent() && (
                  <CorporateHeader
                    labels={{
                      organizationName: contentStore.currentPage?.organizationNameLabel,
                      organizationNumber: contentStore.currentPage?.organizationNumberLabel,
                    }}
                  />
                )}
                <Comp location={location} store={store} />
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </>
    );
  });

  return withStore(authorize(Root));
};
