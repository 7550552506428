import { observer } from "mobx-react";
import React from "react";
import { useFetchData, useStores } from "netbank-shared/src/hooks";
import { IPromiseToPayPage } from "netbank-shared/src/libs/models/Content/Page";
import { PromiseToPayStatusRequest } from "netbank-shared/src/libs/models/Content/Enums";
import { PromiseToPayForm } from "./PromiseToPayForm";
import { tx } from "netbank-shared/src/libs/i18n";
import { SelfServiceFailurePage } from "../SelfServiceFailurePage";
import { SelfServiceFormResult } from "../SelfServiceFormPage/SelfServiceFormResult";
import { NotificationLevel } from "netbank-shared/src/libs/models/Content/Notification";
import { getProductUrl } from "netbank-shared/src/libs/utils/url";
import { useDeterminePromiseToPayParamsByAccountType } from "netbank-shared/src/libs/determineLoadingParams";
import { AccountActionContainer } from "~views/shared/AccountAction/AccountActionContainer";

export const PromiseToPayPage = observer(() => {
  const { contentStore, rootStore } = useStores();
  const { currentPage } = contentStore;

  const pageData = currentPage as IPromiseToPayPage;
  const baseAccount = rootStore.currentAccount;
  const { cleanUp, promiseToPayStatus, currentAccount } = useDeterminePromiseToPayParamsByAccountType(
    baseAccount?.accountType,
    baseAccount?.accountId,
  );

  const invoiceInfo = currentAccount?.canPromiseToPayInvoiceInformation;

  useFetchData({
    cleanup: cleanUp,
  });

  if (!baseAccount || !currentAccount || !invoiceInfo || !invoiceInfo.invoiceNumber || !invoiceInfo.dueDate) {
    return <SelfServiceFailurePage data={pageData.emptyState} />;
  }

  const renderBodyContent = () => {
    const productUrl = getProductUrl(baseAccount.accountType, baseAccount.accountId);

    let content: { header: string; text: string; buttonLabel: string };
    switch (promiseToPayStatus) {
      case PromiseToPayStatusRequest.Failed:
        window.scrollTo(0, 0);
        content = {
          header: pageData.errorContent?.header || tx("loan.promiseToPay.errorContentHeader"),
          text: pageData.errorContent?.textContent || tx("loan.promiseToPay.errorContentText"),
          buttonLabel: pageData.errorContent?.buttonLabel || tx("loan.promiseToPay.goBackButtonLabel"),
        };
        return (
          <SelfServiceFormResult
            goBackToAccountUrl={productUrl}
            content={content}
            contentType={NotificationLevel.error}
          />
        );
      case PromiseToPayStatusRequest.Success:
        window.scrollTo(0, 0);
        content = {
          header: pageData.successContent?.header || tx("loan.promiseToPay.successContentHeader"),
          text: pageData.successContent?.textContent || tx("loan.promiseToPay.successContentText"),
          buttonLabel: pageData.successContent?.buttonLabel || tx("loan.promiseToPay.goBackButtonLabel"),
        };
        return (
          <SelfServiceFormResult
            goBackToAccountUrl={productUrl}
            content={content}
            contentType={NotificationLevel.success}
          />
        );
      case PromiseToPayStatusRequest.Initial:
      default:
        return (
          <PromiseToPayForm
            data={pageData}
            invoiceInfo={invoiceInfo}
            goToAccountUrl={productUrl}
            baseAccount={baseAccount}
          />
        );
    }
  };

  const pageInfo = {
    title: pageData.pageTitle,
    text: pageData.pageText || "",
    accountInfo: [
      {
        label: pageData.accountNameHeaderLabel || tx("misc.accountName"),
        value: currentAccount.name,
      },
      {
        label: pageData.invoiceHeaderLabel || tx("invoice.invoice"),
        value: invoiceInfo.invoiceNumber || "",
      },
    ],
  };

  return <AccountActionContainer info={pageInfo}>{renderBodyContent()}</AccountActionContainer>;
});
