import { FIPathNames } from "../routing";

export default {
  translation: {
    locale: "fi",
    routing: {
      ...FIPathNames,
      term: "verkkopankin-ehdot",
    },
    routeNames: {
      home: "Yleiskatsaus",
      transfers: "Siirrot",
      messages: "Viestit",
      card: "Kortti",
      loan: "Laina",
      deposit: "Säästötili",
      leasing: "Leasing",
      menu: "Valikko",
    },
    validators: {
      required: "Kenttä {{- name, lower}} on pakollinen",
      email: "Syöttämäsi sähköpostiosoite ei ole kelvollinen",
      phone: "Kelvoton {{- name, lower}}",
      pidn: "Syöttämäsi henkilötunnus ei ole kelvollinen",
      acceptTerms: "Ei voi lähettää ilman ehtojen hyväksymistä",
    },
    invoice: {
      overdueAmount: "Erääntynyt määrä",
      movedToDebtCollection: "Siirretty perintään",
      accountName: "Tilinimi",
      accountNumber: "Sopimusnumero",
      autogiro: "Autogiro",
      bankgiro: "Pankkisiirto",
      customerNumber: "Asiakasnumero",
      loadMore: "Näytä lisää laskuja",
      disclaimer:
        "Välttääksesi korkomaksuja maksa {{- amount }} tässä kuussa. Summa sisältää korolliset ostot,\n käteisnostot ja mahdolliset maksut.",
      downloadInvoice: "Lataa PDF",
      showInvoice: "Näytä lasku",
      dueDate: "Eräpäivä",
      invoice: "Lasku",
      invoiceAndDate: "Lasku {{- date, lower}}",
      invoiceDate: "Laskupäivä",
      invoiceDetails: "Laskutiedot",
      invoiceNumber: "Laskunumero",
      invoiceMethod: "Laskutustapa",
      invoicePeriod: "Laskutuskausi",
      change: "Muuta",
      changeInvoiceMethod: "Muuta laskutustapaa",
      amountToPay: "Maksettava summa",
      minimumPayment: "Minimisumma",
      maximumPayment: "Maksimisumma",
      ocr: "Viitenumero (OCR)",
      plusGiro: "plusGiro",
      stopped: "Lasku pysäytetty.",
      stopText:
        "Olemme vastaanottaneet valitukseesi liittyvän dokumentaation. Laskutus on pysäytetty odottamaan\n ulkopuolisen arvioinnin tulosta. Jos sinulla on kysyttävää, ota yhteyttä numeroon {{- phoneNumber }}.",
      total: "Koko luottomäärä",
      balanceAtDate: "Saldo päivään",
      seeInvoice: "Katso lasku",
      status: "Tila",
      titles: {
        cancelled: "Tuote poistunut, {{- date, lower}}",
        overdue: "Erääntynyt lasku, {{- date, lower}}",
        currentPlaceholder: "Nykyinen lasku",
        current: "Nykyinen lasku, {{- date, lower}}",
        extraPayment: "Haluatko maksaa ylimääräistä?",
        paymentFreeMonth: "Haluatko maksuttoman kuukauden?",
      },
      statuses: {
        due: "Maksamaton",
        cancel: "Peruutettu",
        paid: "Maksettu",
        overdue: "Erääntynyt",
        replaced: "Korvattu",
        pending: "Maksuaikaa jäljellä",
      },
      emptyState: {
        header: "Ei tuloksia!",
        text: "",
      },
      daysDelayed: "päivää myöhässä",
      insuranceFee: "Lainaturva",
      monthlyFeeLoan: "Tilinhoitomaksu",
      monthlyFeeLeasing: "Käsittelymaksu",
      principalPenaltyFee: "Viivästyskorko",
      reminderFee: "Huomautusmaksu",
      terminationFee: "Lopetuskulu",
      promiseToPay: "Maksulupaus",
      remainder: "Avoimena",
    },
    card: {
      accountNumber: "Sopimusnumero",
      accountName: "Tilinimi",
      accountType: "Tilin tyyppi",
      brokenCard: "Rikkoutunut kortti",
      credit: "Luotto",
      coApplicant: "Yhteishakija",
      card: "Kortti",
      insurance: "Vakuutus",
      lostCard: "Kadonnut kortti",
      autogiro: "Autogiro",
      pfm: "Maksuvapaa kuukausi",
      cardNumber: "Korttinumero",
      expiresAt: "Vanhenee",
      creditLimit: "Luottoraja",
      orderCard: "Tilaa kortti",
      order: "Tilaus",
      hasCard: "Kortit saatavilla",
      activateCard: "Aktivoi kortti",
      status: "Kortin tila",
      blockCard: "Estä kortti",
    },
    deposit: {
      withdrawalTimeLabel: "Ilmoitusaika",
      transferTimeLabel: "Siirtoaika",
      myDepositPlusWithdrawalTime: "31 päivää",
      myDepositWithdrawalTime: "1-3 pankkipäivää",
      freeOfCharge: "Ilmaiseksi",
      totalSavingAmount: "Kokonaissäästösumma",
      chooseTransferAccount: "Valitse maksutili",
      chooseReceiverOrCreate: "Valitse tallennettu tili tai syötä uusi",
      accountInformation: "Tilin tiedot",
      accruedInterest: "Kertymättömät korot",
      tax: "Vero",
      totalTransferAmount: "Kokonaissiirto",
      closingDate: "Sulkemispäivä",
      openDate: "Avautumispäivä",
      accountRedemptionErrorHeader: "Jotain meni pieleen",
      accountRedemptionErrorText: "Jotain meni pieleen. Yritä myöhemmin uudelleen.",
      receiverSuccessLabel: "Rahasi maksetaan tilille",
      receiverAccount: "Siirtotili",
      accountOpenDateLabel: "Avautumispäivä",
      closeAccount: "Lopeta tili",
      closeAccountConfirmation: "Lopeta tili",
      closeAccountWarningText: "Jos olet varma, että haluat sulkea tilisi, tätä toimintoa ei voi peruuttaa.",
      availableBalance: "Käytettävissä oleva saldo",
      bookedBalance: "Korkoa tuottava saldo",
      interestCarryingBalance: "Korkoa tuottava saldo",
      terminationFee: "Lopetusmaksu",
      expirationDate: "Sitoutumisajan päättyminen",
      nationalPayoutAccountInformation: "Maksu tapahtuu aina NemKonto-tilillesi.",
    },
    loan: {
      amount: "Alkuperäinen rahoitettu osuus",
      credit: "Jäljellä oleva pääoma tänään",
      invoiceDay: "Eräpäivä",
      openDate: "Sopimus alkanut",
      contractEndDate: "Sopimus päättyy alkuperäisen maksusuunnitelman mukaan",
      paymentHolidayMonths: "Lyhennysvapaat kuukaudet",
      displayNumber: "Sopimusnumero",
      description: "Kuvaus",
      coAppliant: "Yhteisvastuullinen",
      contractMileage: "Sopimuskilometrit",
      loanTerminations: {
        accountNumber: "Tilinumero",
        referenceNumber: "Viitenumero",
        goBack: "Sulje",
        amountLabel: "Summa",
        bic: "BIC",
        paymentBreakdown: "Näytä erittely",
        interest: "Korko",
        openInvoices: "Avoimet erät",
        penaltyFees: "Viivästyskulut",
        periodicFees: "Käsittelymaksu",
        restBalance: "Pääoma",
        terminationFee: "Muut kulut",
        prePayment: "Etukäteissuoritus",
        otherExpenses: "Palvelu- ja perintämaksut sis alv",
        loadingAccountError: "Jotain meni pieleen, kun yritettiin ladata tilitietoja.",
        errorContentHeader: "Virhe",
        errorContentText: "Jotain meni pieleen pyynnössäsi.",
        goBackButtonLabel: "Mene takaisin",
        calcButtonLabel: "Tarkista",
        debtorLabel: "Valitse seuraavista vaihtoehdoista",
        debtor: {
          Customer: "Loppuvelan maksaja velallinen itse",
          ThirdParty: "Loppuvelan maksaja kolmas osapuoli, esim. autoliike",
        },
      },
      powerOfAttorney: {
        insuranceCompany: "Vakuutusyhtiösi",
        acceptTerms: "Hyväksyn edellä mainitut ehdot ja kulun",
        countrySpecificationLabel: "Matkustatko johonkin näistä maista?",
        countrySpecification: "Matkustan seuraavissa maissa:",
        goBack: "Takaisin",
        insuranceDocument: "Lataa vakuutusasiakirja",
      },
      promiseToPay: {
        cantPayOnSelectedCalenderDays: "Etkö voi maksaa kalenterissa valittavina olevina päivinä?",
        paymentInformationTitle: "Maksun tiedot",
        required: "Edellytetään",
        errorContentHeader: "Virhe",
        errorContentText: "Jotain meni pieleen pyynnössäsi.",
        messageError: "Tämä kenttä pitää täyttää.",
        successContentHeader: "Menestys",
        successContentText: "Pyyntösi onnistui.",
        goBackButtonLabel: "Mene takaisin",
      },
    },
    selfServiceForm: {
      resultHeader: "Kiitos!",
      goBack: "Takaisin",
      unexpectedErrorText: "Jotain meni pieleen. Yritä myöhemmin uudelleen",
    },
    leasing: {
      accountNumberLabel: "Sopimusnumero",
      additionalInformation: "Lisätiedot",
      openDate: "Sopimus alkanut",
      feesAndInterest: "Palkkiot ja korot",
      contractPeriod: "Sopimusaika",
      elapsedPeriod: "Kulunut aika",
      invoiceFee: "Laskutusmaksu",
      accountInterest: "Tilikorko",
      months: "kuukaudet",
      monthsShort: "kk.",
      description: "Kuvaus",
      licensePlate: "Rekisterinumero",
    },
    transfer: {
      fromAccount: "Lähettäjän tili",
      toAccount: "Vastaanottajan tili",
      amount: "Määrä",
      bank: "Pankki",
      transferMoney: "Siirrä rahaa",
      accountNumber: "Sopimusnumero",
      addNewAccount: "Lisää uusi tili",
      clearingNumber: "Clearing-numero",
      manageSavedAccounts: "Hallinnoi tallennettuja tilejä",
      addAccount: "Lisää tili",
      chooseReceiver: "Valitse vastaanottaja...",
      newReceiverLabel: "Uusi vastaanottaja",
      addNewReceiver: "Lisää vastaanottaja",
      savedAccountsLabel: "Tallennetut vastaanottajat",
      amountToTransfer: "Siirrettävä summa",
      balanceAfterTransfer: "Saldo siirron jälkeen",
      bankTransferFee: "Pankkisiirtomaksu",
      transferErrorHeader: "Jotain meni pieleen",
      transferErrorText: "Jotain meni pieleen. Yritä myöhemmin uudelleen.",
      newTransfer: "Lähetä uusi siirto",
      ownedAccountsLabel: "Omistamasi tilit",
      accountName: "Tilinimi",
      availableAmount: "Käytettävissä oleva määrä",
      transferedAmount: "Siirretty määrä",
      withdrawalSuccessHeader: "Nosto onnistui",
      withdrawalErrorHeader: "Jotain meni pieleen",
      transferSuccessHeader: "Siirto onnistui",
      withdrawalSuccessText: "Nosto onnistui",
      transferFallbackText: "Siirto onnistui",
      forbiddenTransferError: "Kielletty siirto. Tarkista, että olet valinnut oikeat tilit.",
      transferDetailsApiError:
        "Jotain meni pieleen. Yritä myöhemmin uudelleen. Jos ongelma jatkuu, ota yhteyttä asiakaspalveluun.",
    },
    misc: {
      chooseProduct: "Valitse tuote",
      chooseACard: "Valitse kortti",
      fee: "Maksu",
      interest: "Korko",
      effectiveInterest: "Todellinen vuosikorko",
      title: "Verkkopankki",
      logout: "Kirjaudu ulos",
      published: "Julkaistu",
      name: "Nimi",
      accountNumber: "Sopimusnumero",
      accountName: "Tilinimi",
      balance: "Saldo",
      date: "Päiväys",
      purchaseDate: "Ostopäivä",
      dateRange: "Aikaväli",
      foreignAmount: "Summa vieraassa valuutassa",
      exchangeRate: "Valuuttakurssi",
      reservedAmount: "Varattu määrä",
      amount: "Määrä",
      receiver: "Vastaanottaja",
      accountingDate: "Kirjauspäivä",
      transactionDate: "Tapahtumapäivä",
      interestDate: "Korkopäivä",
      paymentDate: "Maksupäivä",
      fromAccount: "Lähettäjän tili",
      message: "Viesti",
      messages: "Viestit",
      ocrMessage: "OCR-numero/viesti",
      youHave: "Sinulla on",
      unreadMessage: "lukematon viesti",
      unreadMessages: "lukemattomat viestit",
      unpaidInvoice: "maksamaton lasku",
      unpaidInvoices: "maksamattomat laskut",
      and: "ja",
      search: "Haku",
      cancel: "Peruuta",
      apply: "Hae",
      applyFor: "Hakea",
      amountFrom: "Määrästä",
      amountTo: "Määrään",
      dateFrom: "Päivämäärästä",
      dateTo: "Päivämäärään",
      selectDate: "Valitse päivämäärä",
      clear: "Tyhjennä",
      clearFilter: "Tyhjennä suodatin",
      select: "Valitse",
      email: "Sähköposti",
      mobile: "Matkapuhelinnumero",
      phone: "Puhelin",
      nameAdress: "Nimi ja osoite",
      sms: "Tekstiviesti",
      internetbank: "Verkkopankki",
      saved: "Tallennettu",
      updateInfo: "Päivitä",
      downloadInfo: "Lataa",
      viewInfo: "Esikatsele",
      licensePlate: "Rekisterinumero",
      fetchMore: "Lataa lisää",
      showLess: "Näytä vähemmän",
      yes: "Kyllä",
      no: "Ei",
      invoiceFee: "Laskutusmaksu",
      bankTransferFee: "Pankkisiirtomaksu",
      atmFee: "Nostomaksu",
      currencyExchangeFee: "Valuutanvaihtomaksu",
      status: "Tila",
      active: "Aktiivinen",
      inactive: "Ei aktiivinen",
      ssn: "Henkilötunnus",
      storeName: "Kuvaus",
      atLeast: "Vähintään",
      upTo: "Enintään",
      exists: "On olemassa",
      missing: "Puuttuu",
      notActive: "Ei aktiivinen",
      other: "Muu",
      send: "Lähetä",
      delete: "Poista",
      save: "Tallenna",
      reserved: "Varattu",
      exportXLSX: "Vie taulukkolaskentaohjelmaan (XLSX)",
      headline: "Otsikko",
      document: "Asiakirja",
      terminate: "Lopeta",
      close: "Sulje",
      summary: "Yhteenveto",
      previous: "Edellinen",
      next: "Seuraava",
      questions: "Kysymykset",
      completed: "valmis",
      sign: "Allekirjoita",
      onwards: "Etene",
      back: "Takaisin",
      chooseCitizenshipPlaceholder: "Aloita kirjoittaminen, valitse maa luettelosta",
      somethingWentWrong: "Jotain meni pieleen",
      information: "Tiedot",
      choose: "Valit",
      showDetails: "Näytä yksityiskohdat",
      contractNumber: "Sopimusnumero",
      organizationNumber: "Organisaation numero",
      organizationName: "Yritys",
      reload: "Lataa uudelleen",
      productType: "Tilin tyyppi",
      viewAll: "Näytä kaikki",
      viewLess: "Näytä vähemmän",
      unexpectedErrorText: "Jotain meni pieleen. Yritä myöhemmin uudelleen",
    },
    file: {
      desktopLabel: "Pudota tiedostot tähän tai ",
      desktopLabelSuffix: "lähetä tiedosto",
      mobileLabel: "Lähetä tiedostot tästä",
      maxFileSizeLabel: "Suurin tiedostokoko",
      maxCombinedFileSizeLabel:
        "Yhdistetty tiedostokoko ylittää  {{- limit}}. Vähennä tiedostojen määrää tai valitse pienemmät tiedostot.",
      maxAttachmentsError: "Liitetiedostojen suurin sallittu määrä on {{- count}}.",
      acceptedExtensionsLabel: "Sallitut tiedostomuodot",
      acceptedExtensions: "{{- extensions, to_sentence}}",
      attachmentValidationError:
        "Lataus epäonnistui. Vältä päällekkäisiä tiedostonimiä ja varmista, että käytät vain {{- extensions, to_sentence}} -tiedostoja. Kukin tiedosto ei saa ylittää 10 MB.",
      duplicateAttachmentOrSizeError:
        "Lataus epäonnistui. Yksi tai useampi valituista tiedostoista on jo ladattu. Kukin tiedosto ei saa ylittää 10 MB.",
      singleFileAttachmentError:
        "Jotain meni pieleen {{- fileName}} latauksen aikana.. Tarkista tiedoston muoto sekä koko ja yritä uudelleen.",
    },
    date: {
      periodOfTime: "Ajanjakso",
      last30: "Viimeiset 30 päivää",
      last3Months: "Viimeiset 3 kuukautta",
      thisYear: "Tähän mennessä tänä vuonna",
      months: "kuukautta",
      lastDayOfMonth: "Kuukauden viimeinen päivä",
      newDueDay: "Uusi eräpäivä on",
      newAgreedDueDay: "Uusi sovittu maksupäivä on",
    },
    emptyState: {
      header: "Tuloksia ei löytynyt!",
      text: "Yritä myöhemmin uudelleen!",
    },
    login: {
      header: "Kirjaudu sisään",
      text: "Internetpankissa voit hoitaa pankkiasioitasi helposti silloin kun sinulle sopii.",
      placeholder: "Syötä henkilötunnus",
      buttonText: "Kirjaudu sisään",
      rememberMe: "Muista minut",
      mockData: "Mockdata",
      generalErrorHeader: "Kirjautuminen keskeytettiin",
      generalErrorText: "Jotain meni pieleen kirjautumisen yhteydessä. Yritä kirjautua uudelleen.",
      generalErrorTryAgainText: "Yritä uudelleen",
      loginIn: "Kirjaudutaan sisään...",
      updateRequired: "Sinun on lataettava sovelluksen uusin versio kirjautuaksesi sisään.",
      santanderHomepageURL: "https://www.santanderconsumer.fi",
      profilePickerHeader: "Valitse profiili",
      profilePickerSubHeader: "Valitse, millä profiililla haluat kirjautua sisään",
      privateProfileLabel: "Yksityinen",
      organizationProfileLabel: "Yritys",
    },
    message: {
      inbox: "Saapuneet",
      archive: "Arkisto",
      sent: "Lähetetty",
      toArchive: "Arkistoi",
      selectAccountText: "Valitse tili",
      selectSubjectText: "Valitse aihe",
      selectAreaText: "Valitse alue",
      accountLabelText: "Valitse tilisi",
      subjectLabelText: "Valitse aihe",
      areaLabelText: "Valitse alue",
      composeNewMessage: "Kirjoita uusi viesti",
      newMessage: "Uusi viesti",
      sendMessage: "Lähetä viesti",
      textareaPlaceholder: "Kirjoita viestisi tähän",
      textareaReplyPlaceholder: "Klikkaa kirjoittaaksesi vastauksesi",
      attach: "Liitä",
      faq: "Usein kysytyt kysymykset",
      loadMore: "Lataa lisää sähköposteja",
      reply: "Vastaa",
      prevDialog: "Aikaisempi keskustelu",
      inboxEmpty: "Postilaatikkosi on tyhjä",
      sentEmpty: "Ei lähetettyjä viestejä",
      archiveEmpty: "Sinulla ei ole arkistoituja viestejä",
      productError: "Valitse tili",
      subjectError: "Valitse aihe",
      areaError: "Valitse alue",
      messageError: "Kirjoita viesti",
      fileSizeError: "Tiedosto, jota yritit ladata, oli liian suuri",
      attachmentsError: "Liitetiedostojen enimmäismäärä on saavutettu",
      goBack: "Takaisin",
      general: "Yleistä",
      attachmentInputLabel: "Liitteet",
    },
    placeholders: {
      chooseAccount: "Valitse tili",
      year: "VVVV",
      month: "KK",
    },
    retryPage: {
      title: "Oi voi.",
      text: "Jotain meni pieleen. Yritä ladata sivu uudelleen.",
      reloadLink: "Lataa uudelleen",
    },
    langPicker: {
      label: "Valitse kieli",
      dk: "suomalainen",
      en: "englanti",
    },
    withdrawal: {
      nationalPayoutAccount: "NemKonto",
      withdrawal: "Nosto",
    },
    compliance: {
      bannerButton: "Kysymyksiin",
      validationErrorText: "Tapahtui odottamaton virhe. Tarkista, että kaikki vastaukset on täytetty.",
      unexpectedErrorText: "Jotain meni pieleen. Yritä myöhemmin uudelleen",
      processingHeader: "Kiitos!",
      processingText: "Käsittelemme hakemusta tällä hetkellä. Odota hetki.",
    },
    updateContactInfo: {
      changeInfo: "Muuta omia tietoja",
      phoneLabel: "Puhelin",
      emailLabel: "Sähköposti",
      streetLabel: "Katuosoite",
      postCodeLabel: "Postinumero",
      cityLabel: "Paikkakunta",
      countryLabel: "Maa",
      noPreferenceSelectedText: "Et ole vielä antanut Santanderille markkinointilupia",
      partnerConsentTitle: "Minulle saa lähettää etuja ja vinkkejä seuraavilta Santanderin yhteistyökumppaneilta:",
      updatePartnerConsentTitle: "Kumppanin suostumukset",
    },
    addDisbursementAccount: {
      invalidAccountNumber: "Virheellinen tilinumero",
      fieldIsRequired: "Tarvitaan",
      inputFieldsMustMatchErrorText: "Vahvistuskentän on oltava sama",
      inputPlaceholder: "esim. FI12 3456 7891 0111 12",
    },
    selfServiceLabels: {
      phoneLabel: "Puhelin",
      messageLabel: "Viesti",
      fileUploaderLabel: "Liitteet",
      termsText: "Toteutuneesta maksusuunnitelman muutoksesta perimme hinnastomme mukaisen kulun {selfServiceFee} €",
      termsCheckboxLabel: "Hyväksyn edellä mainitut ehdot ja kulun.",
      incomeLabel: "Vuosiansiot",
      employmentTypeLabel: "Työsuhde",
      employmentType: {
        Permanent: "Vakituinen",
        Temporary: "Määräaikainen",
        Retired: "Eläkeläinen",
        PartTime: "Osa-aikainen",
        Student: "Opiskelija",
        Unemployed: "Työtön",
      },
      employerLabel: "Työnantaja",
      occupationLabel: "Ammatti",
      housingTypeLabel: "Asumismuoto",
      housingType: {
        Owned: "Omistus",
        Rental: "Vuokra",
        SharedHousehold: "Vanhempien luona",
      },
    },
    reduceInstallments: {
      amountLabel: "Suorituksen summa",
      mileageLabel: "Kilometrit",
    },
    increaseInstallments: {
      amountLabel: "Uusi summa",
      typeLabel: "Suuremman suorituksen kohdistaminen",
      increaseInstallmentsType: {
        Balance: "Pääomaan",
        BalloonPayment: "Pienentää viimeistä suurempaa erää",
      },
    },
    addInstallment: {
      amountLabel: "Maksettava summa",
      dateLabel: "Maksupäivä",
      typeLabel: "Suuremman suorituksen kohdistaminen",
      addInstallmentType: {
        Balance: "Pääomaan",
        NextPayment: "Seuraaville erille",
        BalloonPayment: "Pienentää viimeistä suurempaa erää",
      },
    },
    extendResidualAmountPayment: {
      amountLabel: "Suorituksen määrä",
      mileageLabel: "Kilometrit",
    },
    mileageChange: {
      currentMileageLabeL: "Kilometrit tällä hetkellä",
      requestedMileageLabel: "Kokonaiskilometrit, arvio",
      invalidRequestedMileage: "Kokonaiskilometrit arvio ei voi olla pienempi kuin kilometrit tällä hetkellä",
      requestedMileage: {
        LessThanThirtyThousand: "Alle 30 000",
        BetweenThirtyAndFortyFiveThousand: "30 000 - 45 000",
        BetweenFortyFiveAndSixtyThousand: "45 000 - 60 000",
        BetweenSixtyAndSeventyFiveThousand: "60 000 - 75 000",
        BetweenSeventyFiveAndNinetyThousand: "75 000 - 90 000",
      },
    },
    terminateInsurance: {
      terminateInsuranceReasonLabel: "Irtisanomisen syy",
      reasons: {
        NoLongerNeeded: "En näe tarvetta vakuutukselle",
        NotEligible: "En täytä vakuutuksen myöntämisehtoja",
        NegativeDecision: "Sain kielteisen korvauspäätöksen",
        TooExpensive: "Vakuutus on liian kallis",
        NotSuitable: "Vakuutus ei sovi minulle",
        Other: "Muu syy",
      },
    },
    paymentHoliday: {
      monthsPickerLabel: "Valitse",
    },
    countries: {
      albania: "Albania",
      andorra: "Andorra",
      armenia: "Armenia",
      austria: "Itävalta",
      azerbaijan: "Azerbaidžan",
      belarus: "Valko-Venäjä",
      belgium: "Belgia",
      bosniaAndHerzegovina: "Bosnia ja Hertsegovina",
      bulgaria: "Bulgaria",
      croatia: "Kroatia",
      cyprus: "Kypros",
      czechRepublic: "Tšekin tasavalta",
      denmark: "Tanska",
      estonia: "Viro",
      finland: "Suomi",
      france: "Ranska",
      georgia: "Georgia",
      germany: "Saksa",
      greece: "Kreikka",
      hungary: "Unkari",
      iceland: "Islanti",
      ireland: "Irlanti",
      italy: "Italia",
      kazakhstan: "Kazakstan",
      kosovo: "Kosovo",
      latvia: "Latvia",
      liechtenstein: "Liechtenstein",
      lithuania: "Liettua",
      luxembourg: "Luxemburg",
      northMacedonia: "Pohjois-Makedonia",
      malta: "Malta",
      moldova: "Moldova",
      monaco: "Monaco",
      montenegro: "Montenegro",
      netherlands: "Alankomaat",
      norway: "Norja",
      poland: "Puola",
      portugal: "Portugali",
      romania: "Romania",
      russia: "Venäjä",
      sanMarino: "San Marino",
      serbia: "Serbia",
      slovakia: "Slovakia",
      slovenia: "Slovenia",
      spain: "Espanja",
      sweden: "Ruotsi",
      switzerland: "Sveitsi",
      turkey: "Turkki",
      ukraine: "Ukraina",
      unitedKingdom: "Yhdistynyt kuningaskunta",
      vaticanCity: "Vatikaanivaltio",
      iran: "Iran",
      israel: "Israel",
      morocco: "Marokko",
      tunis: "Tunis",
    },
    insuranceCompany: {
      lahitapiola: "Lähitapiola",
    },
    gdpr: {
      dataEmptyState: {
        header: "Ei tuloksia!",
        text: "",
      },
      goBack: "Takaisin",
    },
    aria: {
      navLabel: "Sivunavigointi",
    },
  },
};
