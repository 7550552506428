import React from "react";
import promiseToPayStyles from "./PromiseToPayPage.scss";
import { tx } from "netbank-shared/src/libs/i18n";
import { formatDate } from "netbank-shared/src/libs/utils";
import { LabelRow } from "~views/shared/LabelRow/LabelRow";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { useDeterminePromiseToPayParamsByAccountType } from "netbank-shared/src/libs/determineLoadingParams";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatePickerFormField } from "~views/shared/Forms/DatePickerFormField";
import { FormWrapper } from "~views/shared/Forms";
import { generatePromiseToPayForm } from "netbank-shared/src/libs/forms/PromiseToPay/PromiseToPayForm";
import { IPromiseToPayForm } from "netbank-shared/src/libs/forms/PromiseToPay/PromiseToPay";
import { IPromiseToPayProps } from "./PromiseToPayForm";

export const PromiseToPayDKForm = observer(({ data, invoiceInfo, goToAccountUrl, baseAccount }: IPromiseToPayProps) => {
  const { submitPromiseToPayRequest, cleanUp } = useDeterminePromiseToPayParamsByAccountType(
    baseAccount.accountType,
    baseAccount.accountId,
  );

  const today = new Date();
  const dueDate = new Date(invoiceInfo.dueDate);

  let minDay = dueDate.getDate() + 1;

  if (today.getMonth() === dueDate.getMonth()) {
    minDay = today.getDate();
    if (minDay === dueDate.getDate()) minDay += 1;
  }

  const minDate = new Date(dueDate.getFullYear(), dueDate.getMonth(), minDay);

  const maxDate = new Date(invoiceInfo.dueDate);
  maxDate.setDate(25);

  const disabledDates = [{ before: minDate, after: maxDate }];

  const defaultValues = {
    cantPayOnSelectableDates: false,
  };

  const methods = useForm<IPromiseToPayForm>({
    resolver: yupResolver(
      generatePromiseToPayForm({
        promiseToPayDateError: data.dayRequiredText,
      }),
    ),
    defaultValues,
  });

  const navigate = useNavigate();

  const goBackToAccount = () => {
    methods.reset();
    cleanUp();
    navigate(goToAccountUrl);
  };

  const formValues = methods.watch();
  const showPromiseToPayDateCalcDate = !!formValues.promiseToPayDate;

  const paymentInfoRows: { label: string; data?: string }[] = [
    {
      label: data.invoiceLabel || tx("invoice.invoiceNumber"),
      data: invoiceInfo.invoiceNumber,
    },
    {
      label: data.newDueDateLabel || tx("date.newDueDay"),
      data: formatDate(formValues.promiseToPayDate),
    },
  ];

  const onSubmitForm: SubmitHandler<IPromiseToPayForm> = async (formData) =>
    submitPromiseToPayRequest(baseAccount.accountId, formData);

  return (
    <FormWrapper
      formMethods={methods}
      onSubmit={onSubmitForm}
      cancelAction={goBackToAccount}
      submitLabel={data.promiseToPayRequestButtonLabel || tx("misc.send")}
      cancelLabel={tx("selfServiceForm.goBack")}
    >
      <div className={promiseToPayStyles.promiseToPayWrapper}>
        <DatePickerFormField
          label={tx("date.newDueDay")}
          name="promiseToPayDate"
          disabledDays={disabledDates}
          initialMonth={minDate}
          infoPopover={{
            popoverTitle: data.dayInputInfoTitle,
            content: data.dayInputInfoText,
            icon: "question",
          }}
        />

        {showPromiseToPayDateCalcDate && (
          <div className={promiseToPayStyles.contentWrapper}>
            <LabelRow label={data.paymentInformationTitle || tx("loan.promiseToPay.paymentInformationTitle")} />
            <div className={promiseToPayStyles.paymentInfo}>
              {paymentInfoRows.map((p, i) => {
                return (
                  <div className={promiseToPayStyles.row} key={`paymentInfoRow-${i}`}>
                    <span className={promiseToPayStyles.label}>{p.label}</span>
                    <span>{p.data}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </FormWrapper>
  );
});
