import { observer } from "mobx-react";
import React, { createRef, useRef } from "react";
import { Link } from "react-router-dom";
import logoutIconFallback from "~assets/logout.svg";
import mailIcon from "~assets/mail.svg";
import mailIconFull from "~assets/mail-full.svg";
import userIcon from "~assets/user-red.svg";
import userIconFull from "~assets/user-full.svg";
import { useStores } from "netbank-shared/src/hooks";
import { tx } from "netbank-shared/src/libs/i18n";
import { IImage } from "netbank-shared/src/libs/models/Content/Image";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";
import { Button, DynamicLink, HamburgerNav, Image } from "..";
import styles from "./Header.scss";
import { LangPicker } from "../LangPicker";
import { HeaderMenuItem } from "netbank-shared/src/libs/models/Content/Enums";

export interface IHeaderProps {
  logoOnly?: boolean;
}

export const Header = observer(({ logoOnly = false }: IHeaderProps) => {
  const { contentStore, uiStore, messageStore, rootStore, securePersistentStore } = useStores();
  const { currentPage } = contentStore;
  const { showMessageViews } = uiStore;

  const logoutTitle = currentPage?.logoutLink?.[0]?.title || currentPage?.logoutLink?.[0]?.text || tx("misc.logout");
  const logoutIcon = currentPage?.logoutIcon?.url || logoutIconFallback;
  const logoutIconActive = currentPage?.logoutIconActive?.url || logoutIconFallback;

  const customerProfileButtonTitle = currentPage?.customerProfileButtonTitle;

  const observerConfig = { attributes: true, childList: true };

  const headerMessageSpanRef = React.useRef<HTMLSpanElement>(null);
  const headerLogoutSpanRef = React.useRef<HTMLSpanElement>(null);
  const headerCustomerProfileSpanRef = React.useRef<HTMLSpanElement>(null);

  const spanObserver = new MutationObserver((mutationList) => {
    for (const mutation of mutationList) {
      if ((mutation.target as HTMLSpanElement).getAttribute("data-name") === HeaderMenuItem.Messages) {
        uiStore.setHeaderMessageDataText(mutation.target.textContent || "");
      }
      if ((mutation.target as HTMLSpanElement).getAttribute("data-name") === HeaderMenuItem.Logout) {
        uiStore.setHeaderLogoutDataText(mutation.target.textContent || "");
      }
      if ((mutation.target as HTMLSpanElement).getAttribute("data-name") === HeaderMenuItem.CustomerProfile) {
        uiStore.setHeaderCustomerProfileDataText(mutation.target.textContent || "");
      }
    }
  });

  React.useEffect(() => {
    // Mutation observers used to translate "data-text" when a user uses the "translate page" browser feature
    if (headerMessageSpanRef.current) {
      spanObserver.observe(headerMessageSpanRef.current, observerConfig);
    }
    if (headerLogoutSpanRef.current) {
      spanObserver.observe(headerLogoutSpanRef.current, observerConfig);
    }
    if (headerCustomerProfileSpanRef.current) {
      spanObserver.observe(headerCustomerProfileSpanRef.current, observerConfig);
    }
    return () => {
      spanObserver.disconnect();
    };
  }, [headerMessageSpanRef.current, headerLogoutSpanRef.current, headerCustomerProfileSpanRef.current]);

  const menuReferences = useRef([createRef(), createRef(), createRef()]);

  const logotype = uiStore.isMobile ? currentPage?.mobileLogotype?.url : currentPage?.logotype?.url;
  const { isMobile } = uiStore;

  const corporateCustomerContent = contentStore.getIsCorporateCustomerContent();

  return (
    <div className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        {logotype &&
          (logoOnly ? (
            <img className={styles.logotype} src={logotype} alt="Santander" />
          ) : (
            <Link to={`/${tx("routing.lang")}`}>
              <img className={styles.logotype} src={logotype} alt="Santander" />
            </Link>
          ))}
        {securePersistentStore.accessToken && !uiStore.hasOutdatedBrowser && !logoOnly && (
          <>
            {!isMobile && currentPage && (
              <div className={styles.userIcons}>
                {showMessageViews && currentPage.messageInboxPage && !corporateCustomerContent && !isMobile && (
                  <div className={styles.userIcon}>
                    <DynamicLink
                      ref={menuReferences.current[0]}
                      to={currentPage.messageInboxPage}
                      trackingAction={TrackingAction.Messages}
                      trackingCategory={TrackingCategory.Header}
                    >
                      <Image
                        hoverReference={menuReferences.current[0]}
                        image={
                          {
                            name: tx("misc.messages"),
                            url: mailIcon,
                            altText: "",
                          } as IImage
                        }
                        hoverImage={
                          {
                            name: tx("misc.messages"),
                            url: mailIconFull,
                            altText: "",
                          } as IImage
                        }
                        activeImage={
                          {
                            name: tx("misc.messages"),
                            url: mailIconFull,
                            altText: "",
                          } as IImage
                        }
                      />
                      <span
                        ref={headerMessageSpanRef}
                        data-text={uiStore.headerMessageDataText || tx("misc.messages")}
                        data-name={HeaderMenuItem.Messages}
                      >
                        {tx("misc.messages")}
                      </span>
                      {messageStore.unreadAmount > 0 && (
                        <span className={styles.badge}>{messageStore.unreadAmount}</span>
                      )}
                    </DynamicLink>
                  </div>
                )}
                <div>
                  <LangPicker />
                </div>
                {currentPage?.personalSettingsPage && (
                  <div className={styles.userIcon}>
                    <DynamicLink
                      ref={menuReferences.current[1]}
                      to={currentPage.personalSettingsPage}
                      trackingAction={TrackingAction.MyProfile}
                      trackingCategory={TrackingCategory.Header}
                    >
                      <Image
                        hoverReference={menuReferences.current[1]}
                        image={
                          {
                            name: tx("misc.customer"),
                            url: currentPage.personalSettingsIcon?.url || userIcon,
                            altText: "",
                          } as IImage
                        }
                        hoverImage={
                          {
                            name: tx("misc.customer"),
                            url: currentPage.personalSettingsIconActive?.url || userIconFull,
                            altText: "",
                          } as IImage
                        }
                        activeImage={
                          {
                            name: tx("misc.customer"),
                            url: currentPage.personalSettingsIconActive?.url || userIconFull,
                            altText: "",
                          } as IImage
                        }
                      />
                      <span
                        ref={headerCustomerProfileSpanRef}
                        data-text={uiStore.headerCustomerProfileDataText || customerProfileButtonTitle}
                        data-name={HeaderMenuItem.CustomerProfile}
                      >
                        {customerProfileButtonTitle}
                      </span>
                    </DynamicLink>
                  </div>
                )}
                <div className={styles.userIcon}>
                  <Button
                    ref={menuReferences.current[2]}
                    onClick={() => rootStore.logout()}
                    trackingAction={TrackingAction.LogOut}
                    trackingCategory={TrackingCategory.Header}
                  >
                    <Image
                      hoverReference={menuReferences.current[2]}
                      image={
                        {
                          name: logoutTitle,
                          url: logoutIcon,
                          altText: "",
                        } as IImage
                      }
                      hoverImage={
                        {
                          name: logoutTitle,
                          url: logoutIconActive,
                          altText: "",
                        } as IImage
                      }
                      activeImage={
                        {
                          name: logoutTitle,
                          url: logoutIconActive,
                          altText: "",
                        } as IImage
                      }
                    />
                    <span
                      ref={headerLogoutSpanRef}
                      data-text={uiStore.headerLogoutDataText || logoutTitle}
                      data-name={HeaderMenuItem.Logout}
                    >
                      {logoutTitle}
                    </span>
                  </Button>
                </div>
              </div>
            )}
            {isMobile && (
              <div className={styles.buttonGroup}>
                {currentPage?.messageInboxPage && !corporateCustomerContent && (
                  <DynamicLink
                    to={currentPage?.messageInboxPage || `/${tx("routing.lang")}`}
                    className={styles.mailWithBadge}
                    trackingAction={TrackingAction.Messages}
                    trackingCategory={TrackingCategory.Header}
                  >
                    <img className={styles.mailIcon} src={mailIcon} alt="mail icon" />
                    {messageStore.unreadAmount > 0 && <span className={styles.badge}>{messageStore.unreadAmount}</span>}
                  </DynamicLink>
                )}
                <HamburgerNav />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
});
